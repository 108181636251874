<template>
  <div class='video'>
    <el-row :gutter="20">
      <el-col :span="6">
        <div class="tree">
          <vue-scroll :ops="ops">
            <el-tree v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" :data="treeList" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
          </vue-scroll>
        </div>
      </el-col>
      <el-col :span="18">
        <!-- <board-video /> -->
        <div class="preview">
          <div class="download">
            <el-date-picker style="margin-right:10px" v-model="videoTime" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" @focus="hide" @blur="show" size="small" v-if="backShow">
            </el-date-picker>
            <el-button class="btn_info" @click="goback" v-if="backShow">回放</el-button>
            <!-- <el-button class="btn_info" @click="getMsg">获取</el-button> -->
            <el-button class="btn_info" @click="download">插件下载</el-button>
            <span>（若无插件请点击此按钮进行插件下载并安装）</span>
          </div>
          <el-tabs v-model="tabName" @tab-click="tabClick">
            <el-tab-pane name="0">
              <span slot="label"><i class="el-icon-video-camera"></i> 实时预览</span>

            </el-tab-pane>
            <el-tab-pane name="1">
              <span slot="label"><i class="el-icon-refresh"></i> 录像回放</span>
            </el-tab-pane>
            <base-camera :appkey="appkey" :secret="secret" :ip="ip" :port="port" :playMode="playMode" layout="1x1" ref="camera"></base-camera>
          </el-tabs>

        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import BoardVideo from './components/video.vue'
import { getVideoList } from '@/api'
import { mapGetters } from 'vuex'
export default {
  // components: { BoardVideo },
  data () {
    return {
      defaultProps: {
        label: 'name',
        children: 'children'
      },
      treeList: [],
      // 滚动条参数
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: '#999999', //滚动条颜色
          opacity: 0.6, //滚动条透明度
          'overflow-x': 'hidden',
          minSize: 0.1
        },
        videoData: [],
      },
      tabName: '0',
      loading: false,
      playMode: 0,
      appkey: null,
      secret: '',
      ip: null,
      port: null,
      videoTime: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      backShow: false,
      indexCode: null
    };
  },
  created () {
    this.init()
  },
  mounted () {
    // this.$refs.board.getInit()
  },
  computed: {
    ...mapGetters(['domainName'])
  },
  methods: {
    // 获取左侧树状图数据
    init () {
      let iscid = 2
      switch (this.domainName) {
        case '庆元县':
          this.appkey = '20819438'
          this.secret = 'IstSHPoKzDJCoIyjc1Jm'
          this.ip = '8.136.144.154'
          this.port = 4443
          iscid = 3
          break;
        case '温州市':
          this.appkey = '25938883'
          this.secret = '1aDmQXsVJh1EgEoF31Cf'
          this.ip = '47.111.228.167'
          this.port = 4443
          iscid = 2
          break;
        case '上城区':
          this.appkey = '24983629'
          this.secret = '9vpX1XpElVmWbn4gGAHR'
          this.ip = '39.107.227.244'
          this.port = 443
          iscid = 2
          break;
        case '永嘉县':
          this.appkey = '25938883'
          this.secret = '1aDmQXsVJh1EgEoF31Cf'
          this.ip = '47.111.228.167'
          this.port = 4443
          iscid = 2
          break;
        case '绍兴市':
          this.appkey = '27949473'
          this.secret = 'Roi6FIfvdw0QtOpn77Hl'
          this.ip = '115.29.189.189'
          this.port = 4443
          iscid = 2

          break;
      }
      this.loading = true
      getVideoList(iscid).then(res => {
        this.treeList = res.data.data
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    // 选择播放的视频
    handleNodeClick (data) {
      // console.log(data);
      if (data.children.length === 0) {
        // debugger
        if (this.tabName === '0') {
          this.$refs.camera.startPreview(data.indexCode)
        } else {
          this.$refs.camera.startPlayBack(data.indexCode, this.$moment().subtract(2, 'days'), this.$moment())
        }

      }
      this.indexCode = data.indexCode
    },
    goback () {
      // console.log(this.$moment())
      if (this.indexCode && this.videoTime && this.videoTime.length > 0) {
        this.$refs.camera.startPlayBack(this.indexCode, this.$moment(this.videoTime[0]), this.$moment(this.videoTime[1]))
      } else {
        this.$message.error('请选择对应摄像头或想回放的时间段')
      }
    },
    // tab切换
    tabClick (data) {
      // console.log(this.$refs.board)
      this.playMode = Number(data.name)
      if (data.name === '1') {
        this.backShow = true
      } else {
        this.backShow = false
      }
      this.$refs.camera.destroy()
      this.$refs.camera.initPlugin()
    },
    // 插件下载
    download () {
      window.open("https://mainimg.terabits.cn/VideoWebPlugin.exe", '_self')
    },
    getMsg () {
      this.$refs.camera.cbIntegrationCallBack()
    },
    hide () {
      this.$refs.camera.hide()
    },
    show () {
      this.$refs.camera.show()
    },
  }
};
</script>

<style scoped lang="scss">
.video {
  height: 85vh;
  .el-row {
    height: 100%;
    padding: 30px 20px 10px;
    .el-col {
      height: 100%;
      .tree {
        height: 100%;
        // height: 100%;
        padding: 20px 0 0 10px;
        background: rgba(0, 0, 0, 0.4);
        ::v-deep .el-tree {
          background: none;
          color: #fff;
          height: 100%;
          // overflow: auto;
          .el-tree-node__content:hover {
            background: rgba(43, 94, 143, 0.5);
          }
          .el-tree-node:focus > .el-tree-node__content {
            background: none;
          }
        }
      }
      .preview {
        position: relative;
        .download {
          position: absolute;
          // cursor: pointer;
          z-index: 1000;
          top: -10px;
          right: 0;
          span {
            color: #fff;
            font-size: 14px;
          }
        }
        ::v-deep .el-tabs__header {
          .el-tabs__item {
            color: white;
            font-size: 16px;
            > span {
              display: flex;
              align-items: center;
              > i {
                margin-right: 10px;
                font-size: 18px;
              }
            }
          }
          .is-active {
            color: #409eff;
          }
          .el-tabs__item:focus {
            color: #409eff;
          }
        }
      }
    }
  }
}
</style>
